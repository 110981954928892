<template>
  <div v-if="editStatus">
    <Modal class-name="editmoneyw" v-model="editStatus" title="充值/扣除用户佣金" width="400" @on-ok="commitData" @on-cancel="close" :loading="subLoading" :closable="false">
      <Form label-position="right" :label-width="50" ref="fValidate" :model="editData" :rules="ruleValid" >
        <div class="mouflis">用户昵称：<span>{{data.wx_name||'--'}}</span></div>
        <div class="mouflis">用户手机：<span>{{data.user_phone||'--'}}</span></div>
        <div class="mouflis">佣金余额：<span>{{data.money||'0.00'}}元</span></div>
        <FormItem label="类型" style="margin-bottom: 10px">
          <RadioGroup v-model="editData.type">
            <Radio label="add">充值</Radio>
            <Radio label="reduce">扣除</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="金额" style="margin-bottom: 10px" prop="money">
          <InputNumber v-model="editData.money" min="0" max="99999999" placeholder="请填写调整金额" style="width: 100%;"></InputNumber>
        </FormItem>
        <FormItem label="备注" style="margin-bottom: 10px">
          <Input v-model="editData.remark" type="textarea" :autosize="{minRows: 2,maxRows: 2}" placeholder="请填写备注信息"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name:'edit_money',
  data(){
    return {
      editStatus:false,
      subLoading:true,
      data:{'wx_name':'','user_phone':''},
      editData:{id:'',type:'add',money:'',remark:''},
      ruleValid:{
        money: [
          { required: true, type:'number', message: '金额必填', trigger: 'change' },
          { pattern:/^\d{1,8}$|^\d{1,8}\.\d{1,2}$/, message: '填写的金额不正确', trigger: 'change' },
        ]
      }
    }
  },
  watch:{
    subLoading(e){
      if(!e)
        setTimeout(()=>{
          this.subLoading = true;
        },5)
    }
  },
  methods:{
    open:function(data){
      this.editStatus = true
      this.data = data;
      this.editData.id=data.id;
    },
    commitData:function(){
      let _this = this;
      this.$refs.fValidate.validate((valid) => {
        if (valid) {
          this.requestApi('/adm/editmoney.html', this.editData).then(function (res){
            _this.subLoading = false;
            if(res.status == 1){
              _this.editData={id:'',type:'add',money:'',remark:''};
              _this.$Message.success('操作成功！');
              _this.$emit('saveOk', {reData:res.data});
              _this.editStatus = false;
            }else{
              _this.subLoading = false;
              _this.$Message.error(res.msg);
            }
          })
        }else{
          this.subLoading = false;
        }
      })
    },
    close:function(){
      this.editData={id:'',type:'add',money:'',remark:''};
      this.editStatus = false;
    }
  }
}
</script>

<style lang="less">
  @import "~@/assets/css/user/edit_money";
</style>