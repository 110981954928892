<template>
  <div class="userlist">
    <div class="searchw">
      <Form inline :label-width="52">
        <FormItem label="用户ID" :label-width="53" style="width: 150px">
          <Input maxlength="10" v-model="posdata.id" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="用户手机" :label-width="66" style="width: 166px">
          <Input maxlength="11" v-model="posdata.user_phone" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="用户昵称" :label-width="66" style="width: 166px">
          <Input maxlength="30" v-model="posdata.wx_name" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="实名状态" :label-width="66" style="width: 140px">
          <Select transfer v-model="posdata.realstatus" :class="[posdata.realstatus==0?'no-sel':'']" @on-change="search">
            <Option v-for="(p,k) in cfg.realstatus" :value="k" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="身份证姓名" :label-width="80" style="width: 178px">
          <Input maxlength="30" v-model="posdata.name" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="身份证号码" :label-width="80" style="width: 228px">
          <Input maxlength="18" v-model="posdata.rnum" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="性别" :label-width="40" style="width: 108px">
          <Select transfer v-model="posdata.gender" :class="[posdata.gender=='不限'?'no-sel':'']" @on-change="search">
            <Option v-for="(p,k) in cfg.gender" :value="p" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="" :label-width="10" style="width: 210px">
          <Select transfer v-model="posdata.pid" :class="[posdata.pid=='0'?'no-sel':'']" style="width: 90px;" @on-change="search">
            <Option value="0">不限平台</Option>
            <Option v-for="(p,k) in cfg.platform" :value="k" :key="k">{{p}}</Option>
          </Select>
          <Input maxlength="20" v-model="posdata.num" placeholder="平台UID" style="width: 103px; margin-left: 5px;" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="账号状态" :label-width="66" style="width: 132px">
          <Select transfer v-model="posdata.status" :class="[posdata.status==0?'no-sel':'']" @on-change="search">
            <Option v-for="(p,k) in cfg.status" :value="k" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="晒图状态" :label-width="66" style="width: 152px">
          <Select transfer v-model="posdata.thumb_status" :class="[posdata.thumb_status==0?'no-sel':'']" @on-change="search">
            <Option v-for="(p,k) in cfg.thumb_status" :value="k" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="注册时间" :label-width="66" style="width: 330px">
          <DatePicker transfer type="date" format="yyyy-MM-dd" placeholder="起始时间" style="width: 120px" @on-change="seldate($event,'start_time')"></DatePicker>
          <span style="color: #515a6e;"> ~ </span>
          <DatePicker transfer type="date" format="yyyy-MM-dd" placeholder="结束时间" style="width: 120px" @on-change="seldate($event,'end_time')"></DatePicker>
        </FormItem>
        <FormItem label="" :label-width="0">
          <Button type="default" icon="ios-search" @click="search" @keyup.13="search">搜索</Button>
        </FormItem>
      </Form>
    </div>
    <Table size="small" :columns="columns" :data="data.data" :loading="table_loading">
      <template slot-scope="row" slot="userinfo">
        <div class="uinfow">
          <div class="headimg"><viewer :options="{'url': 'data-source'}"><img :src="row.row.head_img+'?x-oss-process=style/headimg'" :data-source="row.row.head_img" /></viewer></div>
          <div class="uinfo">
            <div>昵称：<span>{{row.row.wx_name||'--'}}</span></div>
            <div>手机：<span>{{row.row.user_phone||'--'}}</span></div>
            <div>性别：<span>{{row.row.gender||'--'}}</span></div>
            <div v-for="(p,k) in row.row.ninfo" :key="k">{{cfg.platform[p.pid]||''}}UID：<span>{{p.num||'--'}}</span></div>
            <div>等级：<span>{{cfg.level[row.row.level_id]||'--'}}</span></div>
            <div>团队业绩：<span>{{row.row.team_achiev||'0.00'}}</span>元</div>
            <div>个人业绩：<span>{{row.row.achiev||'0.00'}}</span>元</div>
            <div>昨日业绩：<span>{{row.row.yes_achiev||'0.00'}}</span>元</div>
            <div>佣金余额：<span>{{row.row.money||'0.00'}}</span>元</div>
          </div>
        </div>
      </template>
      <template slot-scope="row" slot="authinfo">
        <div class="auth">实名：<span v-if="row.row.name">{{row.row.name||''}}</span><span class="authstatus" v-if="row.row.restatus!=1">{{row.row.restatus_txt||''}}</span></div>
        <div class="auth">企业：<span v-if="row.row.cname">{{row.row.cname||''}}</span><span class="authstatus" v-if="row.row.cstatus!=1">{{row.row.cstatus_txt||''}}</span></div>
      </template>
      <template slot-scope="row" slot="recinfo">
        <div class="uinfow" v-if="row.row.rec_id">
          <div class="headimg"><viewer :options="{'url': 'data-source'}"><img :src="row.row.rinfo.head_img+'?x-oss-process=style/headimg'" :data-source="row.row.rinfo.head_img" /></viewer></div>
          <div class="uinfo">
            <div>昵称：<span>{{row.row.rinfo.wx_name||'--'}}</span></div>
            <div>手机：<span>{{row.row.rinfo.user_phone||'--'}}</span></div>
            <div>性别：<span>{{row.row.rinfo.gender||'--'}}</span></div>
            <div>等级：<span>{{cfg.level[row.row.rinfo.level_id]||'--'}}</span></div>
            <div>团队业绩：<span>{{row.row.rinfo.team_achiev||'0.00'}}</span>元</div>
            <div>个人业绩：<span>{{row.row.rinfo.achiev||'0.00'}}</span>元</div>
            <div>昨日业绩：<span>{{row.row.rinfo.yes_achiev||'0.00'}}</span>元</div>
            <div>佣金余额：<span>{{row.row.rinfo.money||'0.00'}}</span>元</div>
          </div>
        </div>
        <div class="uinfow" v-else>
          <div class="headimg"></div>
          <div class="uinfo emptyrec">暂无推荐人</div>
        </div>
      </template>
      <template slot-scope="row" slot="status">
        <div class="statuslis">
          <span>用户状态：</span>
          <i-switch size="large" v-model="data.data[row.index].status" :true-value="1" :false-value="0" :loading="row.row.status_loading" @on-change="changeStatus($event,row.index,'status')">
            <span slot="open">正常</span>
            <span slot="close">禁用</span>
          </i-switch>
        </div>
        <div class="statuslis">
          <span>等级状态：</span>
          <i-switch size="large" v-model="data.data[row.index].level_status" :true-value="1" :false-value="0" :loading="row.row.level_status_loading" @on-change="changeStatus($event,row.index,'level_status')">
            <span slot="open">正常</span>
            <span slot="close">冻结</span>
          </i-switch>
        </div>
        <div class="statuslis">
          <span>晒图状态：</span>
          <i-switch size="large" v-model="data.data[row.index].thumb_status" :true-value="1" :false-value="0" :loading="row.row.thumb_status_loading" @on-change="changeStatus($event,row.index,'thumb_status')">
            <span slot="open">允许</span>
            <span slot="close">禁止</span>
          </i-switch>
        </div>
      </template>
      <template slot-scope="row" slot="action">
        <div class="btnlis">
          <Button type="default" size="small" @click="opentags('/moneylog',{'id':row.row.id,'newpage':1})">佣金记录</Button>
          <Button type="default" size="small" @click="opentags('/moneylog',{'id':row.row.id,'type':1,'newpage':1})">推广记录</Button>
        </div>
        <div class="btnlis">
          <Button type="info" size="small" @click="editReal(row.index)">实名认证</Button>
          <Button type="primary" size="small" @click="editCompany(row.index)">企业认证</Button>
        </div>
        <div class="btnlis">
          <Button type="warning" size="small" @click="editMoney(row.index)">充扣佣金</Button>
          <Button type="success" size="small" @click="editUser(row.index)">修改资料</Button>
        </div>
        <div class="btnlis">
          <Button type="error" size="small" @click="opentags('/moneylog',{'id':row.row.id,'type':2,'newpage':1})">充值记录</Button>
          <Button type="success" size="small" @click="opentags('/moneylog',{'id':row.row.id,'type':4,'newpage':1})">扣除记录</Button>
        </div>
      </template>
    </Table>
    <div class="footer-m">
      <div class="fexport-btn">
        <Button icon="md-download" @click="export_excel" :loading="export_loading" v-if="false">导出Excel</Button>
      </div>
      <Page class-name="pagew page-r" :total="data.total" :current="data.current_page" :page-size="data.per_page" :page-size-opts="[15,30,50,100]" show-sizer show-total @on-change="page" @on-page-size-change="change_pagesize" />
    </div>
    <edit-real ref="editRealRef" @saveOk="init"></edit-real>
    <edit-company ref="editCompanyRef" @saveOk="init"></edit-company>
    <edit-money ref="editMoney" @saveOk="init"></edit-money>
    <edit-user ref="editUser" @saveOk="init"></edit-user>
  </div>
</template>
<script>
import editReal from "@/views/user_auth/edit_real";
import editCompany from "@/views/user_auth/edit_company";
import editMoney from "@/views/user/edit_money";
import editUser from "@/views/user/edit_user";
import XLSX from 'xlsx';
export default {
  name: 'UserList',
  components:{editReal,editCompany,editMoney,editUser},
  data(){
    return {
      options:{
        'url': 'data-source'
      },
      columns:[
        {title: '会员ID',key: 'id',width:100},
        {title: '用户信息',slot: 'userinfo',minWidth:220},
        {title: '实名/企业信息',slot: 'authinfo',minWidth:160},
        {title: '注册时间',key:'reg_time', minWidth:150},
        {title: '推荐人',slot: 'recinfo',minWidth:220},
        {title: '账户状态',slot: 'status',minWidth:105},
        {title: '操作',slot:'action',width:170,align:'center'},
      ],
      data:{},
      cfg:{gender:[],status:[],platform: {},level:{},wtype:{},nameeq:{},realstatus:{},thumb_status:''},
      table_loading:false,
      export_loading:false,
      posdata:{
        id:'',
        user_phone:'',
        wx_name:'',
        realstatus:0,
        name:'',
        rnum:'',
        gender:'不限',
        pid:'0',
        num:'',
        status:0,
        thumb_status:0,
        start_time:'',
        end_time:'',
        page:1,
        page_size:15
      }
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.table_loading=true;
      this.requestApi('/adm/userlist.html',this.posdata).then(function (res){
        _this.table_loading=false;
        if(res.status==1){
          _this.cfg=res.cfg;
          _this.data=res.data;
        }
      })
    },
    /**
     * 搜索
     */
    search:function (){
      this.posdata.page=1;
      this.init();
    },
    /**
     * 实名认证
     */
    editReal:function (key){
      var _this=this;
      this.table_loading=true;
      this.requestApi('/adm/realinfo.html',{'user_id':this.data.data[key].id}).then(function (res){
        _this.table_loading=false;
        if(res.status==1) _this.$refs.editRealRef.open(res.data);
      })
    },
    /**
     * 企业认证
     */
    editCompany:function (key){
      var _this=this;
      this.table_loading=true;
      this.requestApi('/adm/companyinfo.html',{'user_id':this.data.data[key].id}).then(function (res){
        _this.table_loading=false;
        if(res.status==1) _this.$refs.editCompanyRef.open(res.data);
      })
    },
    /**
     * 修改佣金
     */
    editMoney:function (key){
      this.$refs.editMoney.open(this.data.data[key]);
    },
    /**
     * 修改用户资料
     */
    editUser:function (key){
      this.$refs.editUser.open(this.data.data[key],this.cfg);
    },
    /**
     * 切换状态
     */
    changeStatus:function (status,key,name){
      let _this=this,msg='';
      if(name=='status') msg=status?'确认启用该用户吗？':'确认禁用该用户吗？';
      else if(name=='level_status') msg=status?'确认解冻用户等级吗？':'确认冻结用户等级吗？';
      else if(name=='thumb_status') msg=status?'确认允许用户晒图吗？':'确认禁止用户晒图吗？';
      this.$set(this.data.data[key],name+'_loading',true);
      this.confirm(msg,function (){
        _this.requestApi('/adm/change_status.html',{'id':_this.data.data[key].id,'key':name,'value':status}).then(function (res){
          _this.data.data[key][name+'_loading']=false;
          if(res.status!=1){
            _this.data.data[key][name]=status?0:1;
            _this.alertError(res.msg);
          }
        })
      },function (){
        _this.data.data[key][name]=status?0:1;
        _this.data.data[key][name+'_loading']=false;
      })
    },
    /**
     * 选择时间
     */
    seldate:function (event,key){
      this.posdata[key]=event;
    },
    /**
     * 翻页
     */
    page:function (page){
      this.posdata.page=page;
      this.init();
    },
    /**
     * 切换分页条数
     */
    change_pagesize:function (size){
      this.posdata.page_size=size;
      if(this.posdata.page==1) this.init();
    },
    /**
     * 导出Excel
     */
    export_excel:function (){
      let _this=this,title={
        id:'会员ID',
        wx_name:'用户昵称',
        user_phone:'用户手机',
        gender:'性别',
        level_id:'等级',
        team_achiev:'团队业绩',
        achiev:'个人业绩',
        yes_achiev:'昨日业绩',
        money:'佣金余额',
        name:'实名',
        cname:'企业',
        status:'用户状态',
        level_status:'等级状态',
        reg_time: '注册时间',
        rid:'推荐人ID',
        rwx_name:'推荐人昵称',
        ruser_phone:'推荐人手机',
        rgender:'推荐人性别',
        rlevel_id:'推荐人等级',
        rteam_achiev:'推荐人团队业绩',
        rachiev:'推荐人个人业绩',
        ryes_achiev:'推荐人昨日业绩',
        rmoney:'推荐人佣金余额'
      };
      this.export_loading=true;
      this.requestExcel('/adm/export_userlist.html',title,this.posdata).then(function (res){
        _this.export_loading=false;
        if(res.status!=1) return _this.alertError(res.msg);
        const sheet=XLSX.utils.aoa_to_sheet(res.data);
        const book = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(book, sheet, 'Sheet1');
        XLSX.writeFile(book, '会员列表.xlsx');
      });
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/user/userlist";
</style>