<template>
  <div v-if="editStatus">
    <Modal class-name="edituserw" v-model="editStatus" title="修改用户资料" width="500" @on-ok="commitData" @on-cancel="close" :loading="subLoading" :closable="false">
      <Form label-position="right" :label-width="70" ref="fValidate" :model="editData" :rules="ruleValid">
        <FormItem label="用户头像" style="margin-bottom: 10px">
          <viewer class="headimg" :options="{'url': 'data-source'}"><img class="uheadimg" :src="editData.head_img+'?x-oss-process=style/headimg'" :data-source="editData.head_img" /></viewer>
          <upload class="upheadbtn" action="upLoadCard" imgName="head_img" @upSuccess="upSuccess" btn-size="small"></upload>
        </FormItem>
        <FormItem label="用户昵称" style="margin-bottom: 10px" prop="wx_name">
          <Input type="text" v-model="editData.wx_name" maxlength="30" placeholder="请填写用户昵称"></Input>
        </FormItem>
        <FormItem label="用户手机" style="margin-bottom: 10px" prop="user_phone">
          <Input type="text" v-model="editData.user_phone" placeholder="请填写用户手机" style="width: 100%" maxlength="15"></Input>
        </FormItem>
        <FormItem label="性别" style="margin-bottom: 10px">
          <RadioGroup v-model="editData.gender">
            <Radio label="男"></Radio>
            <Radio label="女"></Radio>
          </RadioGroup>
        </FormItem>
        <FormItem :label="p+'UID'" style="margin-bottom: 10px" v-for="(p,k) in cfg.platform" :key="k">
          <Input type="text" v-model="editData.num[k]" maxlength="18">
            <span slot="append" style="font-size: 12px;" v-if="editData.time[k]">{{editData.time[k]||''}}</span>
          </Input>
        </FormItem>
        <FormItem label="上级" style="margin-bottom: 10px">
          <Select v-model="editData.rid" clearable filterable :remote-method="searchrec" :loading="serLoading" :default-label="editData.rid+'/'+editData.rwx_name" transfer placeholder="上级用户">
            <Option :value="0">没有推荐人</Option>
            <Option v-for="(p,index) in rlist" :value="p.id" :key="index">{{p.wx_name}}</Option>
          </Select>
        </FormItem>
        <FormItem label="业绩" style="margin-bottom: 10px">
          <Input type="number" v-model="editData.achiev" maxlength="11" placeholder="请填写用户业绩"></Input>
        </FormItem>
        <FormItem label="用户密码" style="margin-bottom: 10px" prop="pwd_txt">
          <Input type="password" password v-model="editData.pwd_txt" maxlength="32" placeholder="请填写用户密码"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import upload from "@/components/public/upload";
export default {
  name:'edit_user',
  components:{upload},
  data(){
    return {
      editStatus:false,
      subLoading:true,
      editData:{},
      cfg:{platform:{}},
      rlist:[],
      ruleValid:{
        wx_name: [{ required: true, message: '昵称必填', trigger: 'blur' }],
        user_phone: [{required: true, message: '手机必填', trigger: 'blur' }],
        pwd_txt: [{ required: true, message: '密码必填', trigger: 'blur' },{ min:6, max:32, message: '密码长度必须为6~32位', trigger: 'blur' }]
      },
      serLoading:false
    }
  },
  watch:{
    subLoading(e){
      if(!e)
        setTimeout(()=>{
          this.subLoading = true;
        },50);
    }
  },
  methods:{
    open:function(data,cfg){
      this.editStatus = true
      this.editData=JSON.parse(JSON.stringify(data));
      this.editData.rid=this.editData.rinfo.id;
      this.editData.rwx_name=this.editData.rinfo.wx_name;
      this.editData.num={};
      this.editData.time={};
      for (var k in cfg.platform){
        this.editData.num[k]='';
        this.editData.time[k]=null;
        for (var i in this.editData.ninfo){
          if(this.editData.ninfo[i].pid==k){
            this.editData.num[k]=this.editData.ninfo[i].num;
            this.editData.time[k]=this.editData.ninfo[i].num_time;
          }
        }
      }
      delete this.editData.ninfo;
      delete this.editData.rinfo;
      this.cfg=cfg;
      var _this=this;
      this.$nextTick(function (){
        if(data.rid>0) _this.rlist=[{'id':data.rid,'wx_name':data.rid+'/'+data.rwx_name}];
      });
    },
    /**
     * 提交保存
     */
    commitData:function(){
      let _this = this;
      this.$refs.fValidate.validate((valid) => {
        if (valid) {
          this.requestApi('/adm/save_user.html', this.editData).then(function (res){
            _this.subLoading = false;
            if(res.status == 1){
              _this.editData={};
              _this.$Message.success(res.msg);
              _this.$emit('saveOk');
              _this.editStatus = false;
            }else{
              _this.subLoading = false;
              _this.$Message.error(res.msg);
            }
          })
        }else{
          this.subLoading = false;
        }
      })
    },
    /**
     * 推荐人搜索
     */
    searchrec:function (query){
      let _this=this;
      query=query.replace(/^\d*\//,'');
      this.serLoading=true;
      if(query!=''){
        this.requestApi('/adm/searchrec.html',{'keyword':query}).then(function (res){
          setTimeout(function (){
            _this.serLoading=false;
            _this.rlist=res.data;
          },200);
        })
      }else{
        setTimeout(function (){
          _this.serLoading=false;
          _this.rlist=[{'id':_this.editData.rid,'wx_name':_this.editData.rid+'/'+_this.editData.rwx_name}];
        },200);
      }
    },
    /**
     * 上传头像
     */
    upSuccess:function(e){
      this.editData.head_img=e.response.url;
    },
    close:function(){
      this.editData={id:'',money:'',remark:''};
      this.editStatus = false;
    }
  }
}
</script>

<style lang="less">
  @import "~@/assets/css/user/edit_user";
</style>