<template>
  <div v-if="editStatus">
    <Modal v-model="editStatus" title="实名认证" width="600" @on-ok="commitData" @on-cancel="close" :loading="subLoading" :closable="false">
      <Form label-position="left" :label-width="90" ref="fValidate" :model="editData" :rules="ruleValid" >
        <FormItem label="证件照片" style="margin-bottom: 10px" prop="img">
          <div v-viewer>
            <div class="img-content">
              <upload v-if="editData.img_face == ''" action="upLoadCard" imgName="face" @upSuccess="upSuccess" @upProgress="upProgress">
                <div slot="btn_view" title="点击上传" alt="点击上传" class="img-btn img-btn-t"></div>
              </upload>
              <div class="img-m" v-else >
                <img class="img" v-lazy="editData.img_face" alt="点击放大" title="点击放大" >
                <a href="javascript:;" class="btn-close iconfont" @click="delImg('img_face')">&#xe7fd;</a>
              </div>
              <Spin fix v-if="progress.percent_face!=-1">
                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                <div>上传中({{progress.percent_face}}%)</div>
              </Spin>
            </div>
            <div class="img-content">
              <upload v-if="editData.img_back == ''" action="upLoadCard" imgName="back" @upSuccess="upSuccess" @upProgress="upProgress">
                <div slot="btn_view" title="点击上传" alt="点击上传" class="img-btn img-btn-b"></div>
              </upload>
              <div class="img-m" v-else >
                <img class="img" v-lazy="editData.img_back"  alt="点击放大" title="点击放大">
                <a href="javascript:;" class="btn-close iconfont" @click="delImg('img_back')">&#xe7fd;</a>
              </div>
              <Spin fix v-if="progress.percent_back!=-1">
                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                <div>上传中({{progress.percent_back}}%)</div>
              </Spin>
            </div>
          </div>
        </FormItem>
        <FormItem label="姓名" style="margin-bottom: 10px" prop="name">
          <Input v-model="editData.name" maxlength="10"></Input>
        </FormItem>
        <FormItem label="身份证号" style="margin-bottom: 10px" prop="num">
          <Input v-model="editData.num" maxlength="20"></Input>
        </FormItem>
        <FormItem label="性别" style="margin-bottom: 10px">
          <Input v-model="editData.sex" readonly></Input>
        </FormItem>
        <FormItem label="证件有效期" style="margin-bottom: 10px">
          <Input v-model="editData.end_date"></Input>
        </FormItem>
        <FormItem label="认证状态" style="margin-bottom: 10px">
          <RadioGroup v-model="editData.status">
            <Radio :label="1">认证通过</Radio>
            <Radio :label="0">认证不通过</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="认证备注" style="margin-bottom: 10px">
          <Input v-model="editData.msg" type="textarea" :autosize="{minRows: 2,maxRows: 2}" placeholder="认证审核备注"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import upload from "@/components/public/upload";
export default {
  name:'edit_real',
  components:{
    upload
  },
  data(){
    return {
      progress:{
        'percent_face':-1,
        'percent_back':-1,
      },
      editStatus:false,
      subLoading:true,
      editData:{
        img_face:'',img_back:''
      },
      ruleValid:{
        name: [
          { required: true, message: '此项必填', trigger: 'blur' }
        ],
        num: [
          { required: true, message: '此项必填', trigger: 'blur' }
        ]
      }
    }
  },
  watch:{
    subLoading(e){
      if(!e)
        setTimeout(()=>{
          this.subLoading = true;
        },50)
    }
  },
  methods:{
    open:function(editData){
      this.editStatus = true
      this.editData = JSON.parse(JSON.stringify(editData));
    },
    commitData:function(){
      let _this = this;
      this.$refs.fValidate.validate((valid) => {
        if (valid) {
          this.requestApi('/adm/comReal.html', {editData:this.editData}).then(function (res){
            _this.subLoading = false;
            if(res.status == 1){
              _this.$Message.success(res.msg);
              _this.$emit('saveOk', {reData:res.data});
              _this.editStatus = false;
            }else{
              _this.subLoading = false;
              _this.$Message.error(res.msg);
            }
          })
        }else{
          this.subLoading = false;
          this.$Message.error('数据未完善!');
        }
      })
    },
    delImg:function(imgName){
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除该图片吗？',
        onOk: () => {
          this.editData[imgName] = '';
        }
      });
    },
    upProgress:function(e){
      this.progress['percent_'+e.name] = e.percent;
    },
    upSuccess:function(e){
      this.editData['img_' + e.name] = e.response.url;
      if(e.response.hasimg == 1){
        if(e.name == 'face'){
          this.editData.name = e.response.data.name;
          this.editData.num = e.response.data.num;
          this.editData.sex = e.response.data.sex;
          this.$refs.fValidate.validate();
        }else if(e.name == 'back'){
          this.editData.end_date = e.response.data.end_date;
        }
      }else{
        this.$Notice.warning({
          title: '提示',
          desc: e.response.message
        });
      }
    },
    close:function(){
      this.editStatus = false;
    }
  }
}
</script>

<style lang="less">
  @import "~@/assets/css/user_auth/edit_real";
</style>